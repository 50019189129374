import React, {useState} from 'react';
import './AppLateral.css';
import Lottie from 'react-lottie-player'
import lottieJson from './img/47384-wifi-location.json';
import lottieJsonHt from './img/104530-hotel-bell.json';
import lottieJsonEdu from './img/75992-online-classes.json';
import lottieJsonRt from './img/31454-food-prepared-food-app.json';
import lottieJsonHg from './img/59875-home.json';
import lottieJsonMap from './img/47956-area-map.json';
import { Button, Modal, Container, Row} from 'react-bootstrap';

function AppLateral() {
  //Hoteleria
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Educacion
  const [showEdu, setShowEdu] = useState(false);
  const handleCloseEdu = () => setShowEdu(false);
  const handleShowEdu = () => setShowEdu(true);

  //Restaurant
  const [showRt, setShowRt] = useState(false);
  const handleCloseRt = () => setShowRt(false);
  const handleShowRt = () => setShowRt(true);

  //Hogar
  const [showHg, setShowHg] = useState(false);
  const handleCloseHg = () => setShowHg(false);
  const handleShowHg = () => setShowHg(true);

  //Contacto
  const [showCont, setShowCont] = useState(false);
  const handleCloseCont = () => setShowCont(false);
  const handleShowCont = () => setShowCont(true);

  return (
    <div className="App">
      <header className="App-header">
      <ul>
        <li><Button variant="link" className="App-link" onClick={handleShow}>Hoteles</Button></li>
        <li><Button variant="link" className="App-link" onClick={handleShowEdu}>Educacion</Button></li>
        <li><Button variant="link" className="App-link" onClick={handleShowRt}>Restaurantes</Button></li>
        <li><Button variant="link" className="App-link" onClick={handleShowHg}>Hogares</Button></li>        
        <li><Button variant="link" className="App-link" onClick={handleShowCont}>Contacto</Button></li>
        <Container>
            <Row className="justify-content-center">
            <Lottie
              loop
              animationData={lottieJson}
              play
              style={{ width: 150, height: 150 }}
            />
            </Row>
          </Container>
      </ul>
      </header>
      {/* HOTELERIA */}
      <Modal show={show} onHide={handleClose}>
       <Modal.Header closeButton>
           <Modal.Title>UyFi - Hoteles</Modal.Title>
         </Modal.Header>
         <Modal.Body>
                  UyFi será la solución wifi para su hotel ofreciéndole un servicio de calidad y control de su red de datos.
                  <p></p>
                  Si necesita cualquier información no dude en ponerse en contacto con nosotros. Estaremos encantados de atenderle,
                  reserva ahora tu consultoría gratuita con tu técnico especializado para comenzar a disfrutar
                  de la máxima velocidad en tu hotel con nuestras Soluciones e Instalaciones de WiFi para hoteles Rápidas y Eficaces.
                  <p></p>
            <Container>
            <Row className="justify-content-center">
            <Lottie
              loop={false} 
              animationData={lottieJsonHt}
              play
              style={{ width: 350, height: 350 }}
            />
            </Row>
          </Container>
          </Modal.Body>
         <Modal.Footer>
           <Button variant="link" onClick={handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      {/* EDUCACION */}
      <Modal show={showEdu} onHide={handleCloseEdu}>
       <Modal.Header closeButton>
           <Modal.Title>UyFi - Educacion</Modal.Title>
         </Modal.Header>
         <Modal.Body>
          La seguridad en Internet es esencial pero lo es aún más en los centros educativos, colegios, universidades y bibliotecas.
          Proteja a su centro del acceso de intrusos a través de su Red y bloquee el acceso a contenido inapropiado en Internet.
          <p></p>
          Aportamos soluciones que garanticen Wifi de calidad y fiable para los centros educativos y para las aulas digitales.
          Obtenga su conexión Wifi y su acceso a Internet en grandes recintos y eventos con muchos usuarios conectados a la vez.
          <p></p>
            <Container>
            <Row className="justify-content-center">
            <Lottie
              loop={false} 
              animationData={lottieJsonEdu}
              play
              style={{ width: 350, height: 350 }}
            />
            </Row>
          </Container>
          </Modal.Body>
         <Modal.Footer>
           <Button variant="link" onClick={handleCloseEdu}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      {/* RESTAURANT */}
      <Modal show={showRt} onHide={handleCloseRt}>
       <Modal.Header closeButton>
           <Modal.Title>UyFi - Restaurantes</Modal.Title>
         </Modal.Header>
         <Modal.Body>
          Tener un servicio WiFi en nuestro establecimiento es una de las razones que hoy en día hacen que nuestros clientes sean fieles a nuestro local y estén por tanto contentos con el servicio.
          Por tanto, no disponer de una red WiFi en nuestro bar, o no ofrecer un buen servicio con la que tenemos puede influir en la perdida de clientes.
          <p></p>
          Si ya hemos tomado la decisión de instalar una red seguro nos surjan dudas. ¿Qué equipo WiFi instalo?", "¿Ofrezco WiFi público gratuito o de pago?",
          "¿Cómo puedo hacer para no implique más trabajo a la hora de trabajar?", ¿Le ponemos una misma encriptación o palabra clave para todos?.
          <p></p>
          Lo podemos Ayudar
          <Container>
            <Row className="justify-content-center">
            <Lottie
              loop={false} 
              animationData={lottieJsonRt}
              play
              style={{ width: 350, height: 350 }}
            />
            </Row>
          </Container>
          </Modal.Body>
         <Modal.Footer>
           <Button variant="link" onClick={handleCloseRt}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      {/* HOGAR */}
      <Modal show={showHg} onHide={handleCloseHg}>
       <Modal.Header closeButton>
           <Modal.Title>UyFi - Hogares</Modal.Title>
         </Modal.Header>
         <Modal.Body>
         Cuando contratamos Internet, las dos formas que tenemos de conectarnos a nuestro router es por cable o por Wi-Fi.
         Mientras que el cable, por lo general, nos ofrece la mejor velocidad y la más estable, para conectarnos desde cualquier lugar de la casa y, sobre todo,
         desde dispositivos móviles es necesario hacerlo a través del Wi-Fi.
         <p></p>
          El Wi-Fi es una tecnología que, en aspectos de velocidad y rendimiento, puede dar muchos problemas.
         <p></p>
          Le podemos dar solucion!!
          <Container>
            <Row className="justify-content-center">
            <Lottie
              loop={false} 
              animationData={lottieJsonHg}
              play
              style={{ width: 253500, height: 350 }}
            />
            </Row>
          </Container>
          </Modal.Body>
         <Modal.Footer>
           <Button variant="link" onClick={handleCloseHg}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      {/* CONTACTO */}
      <Modal show={showCont} onHide={handleCloseCont}>
       <Modal.Header closeButton>
           <Modal.Title>UyFi - Contacto</Modal.Title>
         </Modal.Header>
         <Modal.Body>
          <Container>
            <Row className="justify-content-center">
            <Lottie
              loop={false} 
              animationData={lottieJsonMap}
              play
              style={{ width: 350, height: 350 }}
            />
            </Row>
          </Container>
            <p></p>
            🏢 Direccion: Ituzaingo 1512 - Montevideo
            <p></p>
            📧 Email: info@uyfi.uy
            <p></p>
            ☎️ Telefono: (+598)99185967
            <p></p>
          </Modal.Body>
         <Modal.Footer>
           <Button variant="link" onClick={handleCloseCont}>Cerrar</Button>
        </Modal.Footer>
      </Modal>      
    </div>
  );
}

export default AppLateral;