import React, { useState } from 'react';
import './App.css';
import Lottie from 'react-lottie-player';
import lottieJson from './img/57026-wifi.json';
import lottieJson2 from './img/98260-success.json'
import { Button, Modal, Container, Row } from 'react-bootstrap';
import 'animate.css';



function App() {
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

  return (
    <div className="App">
      <header className="App-header align-top">
      <h1>UyFi</h1>
      <Lottie
          loop
          animationData={lottieJson}
          play
          style={{ width: 350, height: 350 }}
        />
        <h1>Acceso a Internet en todos lados!!</h1>
        <b></b>
        <b></b>
        <Button variant="link" className="App-link animate__animated animate__flip" onClick={handleShow}>Mas Info...</Button>
      </header>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>UyFi - - Acceso a Internet en todos lados!!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Somos especialistas en instalaciones de soluciones WiFi para empresas e instituciones, como hoteles, colegios, clubes, grandes superficies, retailers, museos, estaciones de servicios ¡y muchas más!
          <p></p>
          Ponemos a tu disposición toda nuestra experiencia y las herramientas necesarias para diseñar una red de internet inteligente para tu negocio y hogar.
          <p></p>
          <Container>
            <Row className="justify-content-center">
            <Lottie
              loop={false} 
              animationData={lottieJson2}
              play
              style={{ width: 350, height: 350 }}
            />
            </Row>
          </Container>
          </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      </div>
  );
}

export default App;
