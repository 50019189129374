import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import AppLateral from './AppLateral';
import { Container, Row, Col } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import ReactGA4 from "react-ga4";

ReactGA4.initialize('G-QXFCMJ8LLJ');

//ReactGA.pageview(window.location.pathname + window.location.search);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
          <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>UyFi - Internet en Todos Lados!!!</title>
                <meta name="descripcion" content="Soluciones Wifi - Uruguay" />
            </Helmet>

            <Container>
              <Row>
                <Col sm={4}>
                  <AppLateral />
                </Col>
                <Col sm={8}>
                  <App />
                </Col>
              </Row>
          </Container>
        </div>
  </React.StrictMode>
);

